import React from 'react'

// Components imports
import NotificationList from '../../../components/notification_list'
import AppointmentsList from '../../../components/appointment_list'

// Design imports
import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import UsualActionsIcon from '@material-ui/icons/BookmarkBorder'
import WarningIcon from '@material-ui/icons/Warning'
import NotificationIcon from '@material-ui/icons/Notifications'
import styles from './styles'

class Home extends React.Component {
    goToNewAppointment = () => {
        this.props.history.push('/new-appointment')
    }

    goToNewPatient = () => {
        this.props.history.push('/new-patient')
    }

    goToNewIncidence = () => {
        this.props.history.push('/new-incidence')
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <UsualActionsIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Accions més habituals
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <Grid container spacing={16} className={classes.cardContainer} alignItems="stretch">
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewAppointment()}>
                                                Demanar hora
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewPatient()}>
                                                Donar d'alta un nou pacient
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="primary"
                                                className={classes.quickActionButton}
                                                classes={{ label: classes.label }}
                                                onClick={() => this.goToNewIncidence()}>
                                                Nova incidència
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <WarningIcon color="secondary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="secondary">
                                                Avís sobre peticions de visites
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className={classes.cardContainer}>
                                        <Typography variant="h6" color="textPrimary">
                                            <span style={{ fontWeight: 'bold' }}>COMUNICACIÓ JULIOL-2024</span>
                                        </Typography>
                                        <Typography variant="subtitle1" color="textPrimary">
                                            Us informem que estem en una situació excepcional de saturació de sol·licituds de visita que s'extén fins a 500 sol·licituds en espera d'assignació. Algunes de les peticions pendents daten del mes de març per tant és normal que qualsevol sol·litud posterior estigui encara pendent de resoldre.
                                            Acabem d'obrir  agendes pel proper període docent de setembre/24 a gener/25 i estem assignant visites segons la prioritat indicada i per ordre cronològic de recepció.
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            <span style={{ fontWeight: 'bold' }}>En qualsevol cas si necessiteu consultar o modificar algun detall de les peticions NO CANCEL·LEU la petició. Obriu una incidència a nom del pacient des de la pàgina d'inici</span>
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            Estem implementant mesures que ens permetin augmentar la capacitat assistencial al llarg del proper curs.
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            Agraim per avançat la vostra comprensió i us demanem que pugueu transmetre als vostres usuaris la dificultat de gestionar la situació actual.
                                        </Typography>
                                        <Typography className={classes.alertTextPadding} variant="subtitle1" color="textPrimary">
                                            Telèfon: <span style={{ fontWeight: 'bold' }}>937 398 349</span>
                                        </Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <NotificationIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Últimes peticions d'hora
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    <AppointmentsList />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>
                                    <div className={classes.cardHeader}>
                                        <div className={classes.cardTitleContainer}>
                                            <WarningIcon color="primary" style={{ fontSize: 28 }} />
                                            <Typography className={classes.title} variant="h6" color="primary">
                                                Últimes incidencies
                                            </Typography>
                                        </div>
                                        {/*<Tooltip title="Editar">
                                            <IconButton aria-label="Editar" className={classes.actions}>
                                                <FilterListIcon />
                                            </IconButton>
                                        </Tooltip>*/}
                                    </div>
                                    <Divider />
                                    <NotificationList />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(Home)
